
  import {updateCssClass} from '@/helper/CssClassHelper';
  export default {
    components: {
      ArticleFilter: () => import('@/components/elements/ArticleFilter'),
    },
    props: {
      triggerResetFilter: {
        type: Boolean,
        required: false,
        default: false,
      },
    },
    data() {
      return {
        dialog: false,
        width: 0,
      };
    },
    watch: {
      triggerResetFilter: function (val) {
        if (val) {
          this.resetFilterValues();
        }
      },
    },
    mounted() {
      this.setButtonWidth();
      window.addEventListener('resize', this.setButtonWidth);
      window.onscroll = () => {
        this.setButtonWidth();
        if (document.documentElement.scrollTop > 599) {
          updateCssClass(
            'pb-article-filter-mobile-button',
            'pb-listing-filter-mobile-fixed-button',
            'pb-listing-filter-mobile-nofixed-button'
          );
        } else {
          updateCssClass(
            'pb-article-filter-mobile-button',
            'pb-listing-filter-mobile-nofixed-button',
            'pb-listing-filter-mobile-fixed-button'
          );
        }
      };
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.setButtonWidth);
    },
    methods: {
      setButtonWidth() {
        this.width = window.innerWidth - 24;
      },
      applyFilter(event) {
        this.$emit('article-listing-filter', event);
        this.dialog = false;
      },
      resetFilterValues(event) {
        this.$emit('article-listing-filter-reset', event);
        this.dialog = false;
      },
      closeModal() {
        this.dialog = false;
      },
    },
  };
