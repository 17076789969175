
  import {createObjectFromQueryString} from '@/helper/QueryHelper';
  import {debounce} from 'lodash/function';
  import articleCategoriesWithPublishedArticles from '@/apollo/queries/article/articleCategoriesWithPublishedArticles.gql';

  export default {
    name: 'ArticleFilter',
    props: {
      categoryFilter: {
        type: Boolean,
        required: false,
        default: () => true,
      },
      searchTerm: {
        type: String,
        required: false,
        default: '',
      },
      categories: {
        type: Array,
        required: false,
        default: () => [],
      },
      contentScope: {
        type: String,
        required: false,
        default: 'promotionbasis_candidate',
      },
      triggerResetFilter: {
        type: Boolean,
        required: false,
        default: false,
      },
      filterParams: {
        type: Object,
        required: false,
        default: null,
      },
    },
    data() {
      return {
        search_term_filter: null,
        categories_filter: [],
        articleCategories: [],
        disabled: false,
      };
    },
    apollo: {
      articleCategories: {
        query: articleCategoriesWithPublishedArticles,
        variables() {
          return {
            scope: this.contentScope,
          };
        },
      },
    },
    watch: {
      triggerResetFilter: function (val) {
        if (val) {
          this.resetFilterValues();
        }
      },
      '$route.query': function () {
        this.setupTheSearch();
      },
    },
    created() {
      this.search_term_filter = this.filterParams
        ? this.filterParams.search_term
        : '';
      this.categories_filter = this.filterParams
        ? this.filterParams.categories
        : [];
      if (Object.keys(this.$route.query).length !== 0) {
        this.applyFilterWithThrottle();
      }
      this.setupTheSearch();
    },
    methods: {
      applyFilterWithThrottle: debounce(function () {
        this.applyFilter(true);
      }, 2000),
      applyFilter() {
        this.$emit('article-listing-filter', {
          search_term: this.search_term_filter,
          categories: this.categories_filter,
        });
      },
      setupTheSearch() {
        const filterValues = createObjectFromQueryString(this.$route.query);
        if (filterValues['search']) {
          this.search_term_filter = filterValues['search'];
        } else {
          this.search_term_filter = null;
        }
        if (filterValues['category[]']) {
          this.categories_filter = filterValues['category[]'][0].split(',');
        } else {
          this.categories_filter = [];
        }
      },
      resetFilterValues() {
        this.search_term_filter = null;
        this.categories_filter = [];
        this.$emit('article-listing-filter-reset');
      },
    },
  };
