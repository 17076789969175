
  import ScreenSize from '@/mixins/ScreenSize';
  import countFilterArticles from '@/apollo/queries/article/countFilterArticles.gql';
  import filterArticles from '@/apollo/queries/article/filterArticles.gql';
  import Events from '@/events/Events';
  import ArticleCard from '@/components/elements/ArticleCard';
  import ArticleFilter from '@/components/elements/ArticleFilter';
  import ArticleFilterDialog from '@/components/filter-dialogs/ArticleFilterDialog';
  import Pagination from '@/components/Pagination';
  import JobListingBanner from '@/components/elements/JobListingBanner';
  import NoDataToShow from '@/components/elements/NoDataToShow';

  export default {
    name: 'SectionArticleListingWithFilter',
    components: {
      ArticleCard,
      ArticleFilter,
      ArticleFilterDialog,
      Pagination,
      JobListingBanner,
      NoDataToShow,
      RichText: () => import('@/components/elements/RichText'),
      CustomBigTitle: () => import('@/components/CustomBigTitle'),
      TeaseredArticlesCard: () =>
        import('@/components/elements/TeaseredArticlesCard'),
    },
    mixins: [ScreenSize],
    props: {
      section: {
        type: Object,
        required: false,
        default: () => {},
      },
    },
    data() {
      return {
        page: 1,
        countPages: 0,
        itemsPerPage: this.section.num_news_per_page,
        articlesCount: 0,
        orderBy: 'title:asc',

        articles: [],

        filterParamsSet: false,
        filterParams: {
          search_term: null,
          categories: [],
        },
        triggerResetFilter: false,
        breadcrumbs: [
          {
            text: this.$t('page.index'),
            disabled: false,
            to: this.localePath({
              name: 'index',
            }),
            nuxt: true,
            exact: true,
          },
          {
            text: this.$t('page.articles'),
            disabled: true,
            to: this.localePath({
              name: 'articles',
            }),
            nuxt: true,
            exact: true,
          },
        ],

        orderByItems: [
          {
            value: 'published_at:desc',
            text: this.$t('orderBy.date.desc'),
          },
          {
            value: 'published_at:asc',
            text: this.$t('orderBy.date.asc'),
          },
          {
            value: 'title:desc',
            text: this.$t('orderBy.title.desc'),
          },
          {
            value: 'title:asc',
            text: this.$t('orderBy.title.asc'),
          },
        ],
      };
    },
    head() {
      const head = {meta: []};
      const query = Object.assign({}, this.$route.query);
      const canonical_links = [];

      canonical_links.push({
        rel: 'canonical',
        hid: 'canonical',
        href:
          process.env.NUXT_ENV_BASE_URL +
          this.localePath({
            query: {page: this.page > 1 ? this.page : undefined},
          }),
      });

      if (this.page > 1) {
        query.page = this.page - 1;
        canonical_links.push({
          rel: 'prev',
          hid: 'prev',
          href:
            process.env.NUXT_ENV_BASE_URL +
            this.localePath({
              query: query,
            }),
        });
      }

      if (this.page < this.countPages) {
        query.page = this.page + 1;
        canonical_links.push({
          rel: 'next',
          hid: 'next',
          href:
            process.env.NUXT_ENV_BASE_URL +
            this.localePath({
              query: query,
            }),
        });
      }

      head.link = canonical_links;

      if (this.page > 1) {
        head.meta.push({
          name: 'robots',
          content: 'noindex',
        });
        head.meta.push({
          name: 'googlebot',
          content: 'noindex',
        });
      }

      return head;
    },
    watch: {
      '$route.query': function () {
        this.parsePageFromQuery();
      },
    },
    created() {
      this.filterParamsSet = true;
      if (this.section.filterParams) {
        this.filterParams = this.section.filterParams;
      }
    },
    apollo: {
      articles: {
        query: filterArticles,
        variables() {
          this.parsePageFromQuery();

          const filterParams =
            !this.filterParamsSet && this.section.filterParams
              ? this.section.filterParams
              : this.filterParams;
          let searchTerm = [];
          if (filterParams.search_term) {
            const searchArray = filterParams.search_term.split(' ');
            searchTerm = this.removeEmptyStrings(searchArray);
          }
          return {
            scope: this.section.content_scope,
            sort: this.orderBy,
            limit: this.itemsPerPage,
            start: this.itemsPerPage * (this.page - 1),
            search_term: searchTerm,
            category_slugs: filterParams.categories,
          };
        },
        result({stale, data}) {
          // Apollo calls this function multiple times, on the server and on the client at least once
          // If it's the first visit to this page then it will call it again on the client
          // If the client was on this page already then a cache exists and will be called before the actual network request is made
          // stale false indicates that this is the actual network call
          // @see https://github.com/vuejs/vue-apollo/discussions/492
          let items = [];
          if (data.articles.length > 0) {
            items = data.articles.map((item, key) => ({
              id: item.id,
              position: key + 1,
            }));
          }
          if (process.client && stale === false) {
            this.$emitWithClientData(Events.ArticleListingViewed, {
              itemsPerPage: this.itemsPerPage,
              items: items,
              page: this.page,
            });
          }
        },
      },
      articlesCount: {
        query: countFilterArticles,
        variables() {
          const filterParams =
            !this.filterParamsSet && this.section.filterParams
              ? this.section.filterParams
              : this.filterParams;
          let searchTerm = [];
          if (filterParams.search_term) {
            const searchArray = filterParams.search_term.split(' ');
            searchTerm = this.removeEmptyStrings(searchArray);
          }
          return {
            scope: this.section.content_scope,
            search_term: searchTerm,
            category_slugs:
              filterParams.categories && filterParams.categories.length > 0
                ? filterParams.categories
                : null,
          };
        },
        result({data}) {
          this.articlesCount = data.articlesCount.aggregate.count;
          this.countPages = Math.ceil(this.articlesCount / this.itemsPerPage);
        },
      },
    },
    methods: {
      removeEmptyStrings(array) {
        return array.filter((str) => str !== '');
      },
      parsePageFromQuery() {
        this.page = this.$route.query.page
          ? parseInt(this.$route.query.page)
          : 1;
      },
      pushRouter() {
        const searchParams = new URLSearchParams();
        if (this.filterParams && this.filterParams.search_term) {
          searchParams.append('search', this.filterParams.search_term.trim());
        }
        if (
          this.filterParams &&
          this.filterParams.categories &&
          this.filterParams.categories.length > 0
        ) {
          this.filterParams.categories.forEach((category) => {
            searchParams.append('category[]', category);
          });
        }
        let additionalParam = '';
        if (process.client) {
          const currentSearchParams = new URLSearchParams(
            window.location.search
          );
          currentSearchParams.forEach((value, key) => {
            if (key === 'mtm_group' && searchParams)
              additionalParam = '&mtm_group=' + value;
          });
        }

        this.$router.push(
          this.localePath({}) +
            `?${searchParams}` +
            `&page=${this.page}` +
            additionalParam +
            `#pb-article-filter-row`
        );
      },
      setFilterParams(params) {
        this.filterParams = params;
        this.page = 1;
        this.pushRouter();
      },
      resetFilterParams() {
        this.triggerResetFilter = !this.triggerResetFilter;
        this.filterParams = {};
        this.page = 1;
        this.pushRouter();
      },
    },
  };
