
  export default {
    name: 'Pagination',
    props: {
      length: {
        type: Number,
        required: true,
      },
      page: {
        type: Number,
        required: true,
      },
      totalVisible: {
        type: Number,
        required: false,
        default: 5,
      },
      nextIcon: {
        type: String,
        required: false,
        default: 'fal fa-angle-right',
      },
      prevIcon: {
        type: String,
        required: false,
        default: 'fal fa-angle-left',
      },
    },
    data() {
      return {
        currentPage: 0,
      };
    },
    fetch() {
      this.setPageFromQuery();
    },
    watch: {
      '$route.query': function () {
        this.setPageFromQuery();
      },
    },
    methods: {
      setPageFromQuery() {
        this.currentPage = this.$route.query.page
          ? (this.currentPage = parseInt(this.$route.query.page, 10))
          : (this.currentPage = this.page);
      },
      switchToPage(page) {
        this.$emit('input', page);
      },
    },
  };
